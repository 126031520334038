import { useRecoilState } from "recoil";
import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  INTRO_MSG,
  TOLOCALE_LOCALES,
  TOLOCALE_OPTIONS,
} from "../utils/appConstants";
import SharedDialog from "../components/Shared/dialog";
import { getChatHistory, getAuthCredentials } from "../api/api";
import { chatMesgState, chatHistPagiState, wsCloseState } from "../recoil/atoms";
import AuthenticationService from "../utils/authenticationService";

const AuthRedirect = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [messageList, setMessageList] = useRecoilState(chatMesgState);
  const [chatPagination, setChatPagination] = useRecoilState(chatHistPagiState);
  const [wsClose, setWsClose] = useRecoilState(wsCloseState);

  useEffect(() => {
    if (searchParams?.get("active_session") === null || !(searchParams?.get("active_session"))) {
      navigate("/");
    } else {
      if (searchParams?.get("active_session") === "True") {
        handleOpenDialog();
      } else if (searchParams?.get("active_session") === "False") {
        setLoading(true);
        getAuthInfo();
      } else {
        navigate("/");
      }
    }
  }, []);

  const handleOpenDialog = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
    setLoading(true);
    if(wsClose)
    {
      console.log("wsClose", wsClose);
      wsClose.close();
      setWsClose(null);
    }
    else{
      console.log("wsClose else", wsClose);
    }
   
    getAuthInfo();
  };

  const getAuthInfo = async () => {
    const resp = await getAuthCredentials();
    if (!resp) {
      navigate("/");
      setLoading(false);
    } else {
      const { user_id, user_name, user_email } = resp;
      AuthenticationService.setLocalStorageUserAttributes(
        user_id,
        user_email,
        user_name
      );
      AuthenticationService.setupAxiosInterceptors();
      // trigger API call for getting previous chat history
      const chatResp = {
        data: {}
      };
      // const chatResp = await getChatHistory(chatPagination);
      if (!chatResp) {
      } else {
        // intro message append
        let tempMsgObj = structuredClone(INTRO_MSG);
        tempMsgObj["created_date"] = new Date().toISOString();
        tempMsgObj["data"][0]["created_date"] = new Date().toISOString();
        let tempMsgList = structuredClone(chatResp);
        let currentDate = new Date().toLocaleDateString(
          TOLOCALE_LOCALES,
          TOLOCALE_OPTIONS
        );
        // messageList.hasOwnProperty('currentDate') or currentDate in Object.keys(messageList)
        if (chatResp?.data.hasOwnProperty(currentDate)) {
          // today date is in Object.keys and push message in the array
          let tempCurrentDateMsgList = [
            ...tempMsgList.data[`${currentDate}`],
            tempMsgObj,
          ];
          tempMsgList.data[`${currentDate}`] = tempCurrentDateMsgList;
        } else {
          // add today date in Object.keys and push message in the array
          tempMsgList.data[`${currentDate}`] = [tempMsgObj];
        }

        let tempScrollDivId = "";
        if (Object.keys(chatResp?.data)?.length > 0) {
          tempScrollDivId =
            chatResp.data[Object.keys(chatResp.data)[0]][0]["msg_id"] || "";
        }
        setMessageList({
          wsSendMsg: null,
          data: tempMsgList?.data,
          scrollDivId: tempScrollDivId,
          loadMore: true,
          // loadMore: Object.keys(chatResp?.data)?.length > 0 ? true : false,
        });
        setChatPagination({ ...chatPagination, noRefresh: true });
      }
      navigate("/chat");
      // navigate(state?.sharedRoute || "/chat");
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="auth-redirect-root">
          <FadeLoader
            width={5}
            radius={2}
            margin={2}
            height={15}
            color={"#4279f4"}
            loading={loading}
            // css={overrideContentLoading}
          />
          <div>Loading your chat</div>
        </div>
      ) : (
        <></>
      )}
      <SharedDialog openModal={openModal} handleClose={handleCloseDialog} />
    </>
  );
};

export default AuthRedirect;
